import { useRef, useState } from 'react'
import Button from 'components/Button'
import useInvestments from 'hooks/useInvestments'
import AppLayout from 'layouts/AppLayout'
import { investmentsFormId } from 'utils/make/constants'
import makeTrackings from 'utils/make/trackings'
import InvestmentCard from '../components/InvestmentCard'
import InvestmentModal from '../components/InvestmentModal'
import getDevice from 'utils/envs/getDevice'
import classNames from 'classnames'

import ArrowBack from '../../../../static/images/icons/arrow-back.svg'
import ArrowForward from '../../../../static/images/icons/arrow-forward.svg'

import './InvestmentSelection.scss'

const InvestmentSelection = () => {
  const { investments } = useInvestments()
  const [isModalOpen, setIsModalOpen] = useState(false)

  const isDesktop = getDevice.isDesktop()

  const [activeCard, setActiveCard] = useState(0)

  const slidesRef = useRef(null)

  const handleBackCard = () => {
    if (activeCard === 0) return

    const newState = activeCard - 1

    setActiveCard((state) => state - 1)

    slidesRef.current.isByButton = true

    slidesRef.current.scroll({
      behavior: 'smooth',
      left: newState * 312,
    })

    setTimeout(() => {
      slidesRef.current.isByButton = false
    }, 500)
  }

  const handleForwardCard = () => {
    if (activeCard === 4) return

    const newState = activeCard + 1

    setActiveCard((state) => state + 1)

    slidesRef.current.isByButton = true

    slidesRef.current.scroll({
      behavior: 'smooth',
      left: newState * 312,
    })

    setTimeout(() => {
      slidesRef.current.isByButton = false
    }, 500)
  }

  return (
    <AppLayout
      contentHeaderInfos={{
        title: 'Confira as opções de investimento',
        description:
          'Diferente opções para investir que mais combinam com o seu perfil.',
        onTrack: () => {
          makeTrackings.formBacked({
            formId: investmentsFormId,
            formName: 'investment',
            stepId: '6b116654-bf58-4712-96aa-018570d00f12',
            stepName: 'investment-subproducts',
            stepNumber: 2,
          })
        },
      }}
      contentClassName="investment-selection"
      containerClassName="investment-container"
    >
      <div className="investment-selection__cards">
        <div className="investment-selection__cards-slider" ref={slidesRef}>
          {investments.map((investment, index) => (
            <InvestmentCard
              key={investment.id}
              index={index}
              investment={investment}
            />
          ))}
        </div>

        {isDesktop && (
          <div className="investment-selection__cards-controls">
            <div className="investment-selection__cards-controls-buttons">
              <button
                className="investment-selection__cards-controls-control"
                disabled={activeCard === 0}
                onClick={handleBackCard}
              >
                <ArrowBack />
              </button>
              <button
                className="investment-selection__cards-controls-control"
                disabled={activeCard === 4}
                onClick={handleForwardCard}
              >
                <ArrowForward />
              </button>
            </div>

            <div
              className={classNames(
                'investment-selection__cards-controls-stepper',
                `investment-selection__cards-controls-stepper--${
                  activeCard + 1
                }`
              )}
            />
          </div>
        )}
      </div>

      <Button
        className="investment-selection__button"
        onClick={() => {
          makeTrackings.formContinued({
            formId: investmentsFormId,
            formName: 'investment',
            stepId: '2f0e4886-0f77-4a84-ac39-645826155410',
            stepName: 'investment-subproducts',
            stepNumber: 2,
          })

          setIsModalOpen(true)
        }}
      >
        Comece a investir
      </Button>

      <InvestmentModal isOpen={isModalOpen} setIsOpen={setIsModalOpen} />
    </AppLayout>
  )
}

export default InvestmentSelection
